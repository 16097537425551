import React from 'react';
import DynamicPageComp from '@studionand/depi/src/components/DynamicPage';
import Layout from '@studionand/depi/src/components/DepiLayout';
import Editor from '@studionand/depi/src/components/depi/Editor';
import config from '@studionand/depi/src/components/depi/Header/constants';

const DynamicPage = props => {
  return (
    <DynamicPageComp
      {...props}
      layoutComponent={Layout}
      editorComponent={Editor}
    />
  );
};

DynamicPage.getInitialProps = DynamicPageComp.getInitialProps(config, 'depi');

export default DynamicPage;
